import { remove } from 'lodash'

const STORAGE_ITEM = '_NOVTI_SEARCH'

export default {
  namespaced: true,
  state: {
    novtiSearch: JSON.parse(localStorage.getItem(STORAGE_ITEM)) ?? {
      columns: {},
    },
  },
  getters: {
    getTableColumns: state => resource => state.novtiSearch?.columns[resource] ?? [],
  },
  mutations: {
    SET_TABLE_COLUMNS(state, payload) {
      const { resource, columns } = payload

      state.novtiSearch.columns[resource] = columns

      localStorage.setItem(STORAGE_ITEM, JSON.stringify(state.novtiSearch))
    },
    REMOVE_TABLE_COLUMN(state, column) {
      remove(state.novtiSearch.columns.transactions, columnField => columnField.key === column.key)

      localStorage.setItem(STORAGE_ITEM, JSON.stringify(state.novtiSearch))
    },
  },
}
